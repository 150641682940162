import { React, useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FiletypeDoc, InfoCircleFill } from 'react-bootstrap-icons';
import { Input, Switch, Flex } from 'antd';

import colors from '../../../../../styles/_colors.scss';

const _ = require('lodash');

export const TeamsNotifModal = ({ context }) => {
    const {
        notifImplementationLink,
        enableTeamsNotif,
        teamsChannel,
        teamsWebhook,
        setConfigParams,
        setModalSaveStatus = () => { },
    } = useContext(context);

    const [notifStatus, setNotifStatus] = useState(enableTeamsNotif);
    const [channel, setChannel] = useState(teamsChannel);
    const [webhook, setWebhook] = useState(teamsWebhook);
    const [configParamsToSave, setConfigParamsToSave] = useState({});

    const [showGuideDiv, setShowGuideDiv] = useState(false);

    const initNotifStatus = useRef([]);
    const initChannel = useRef([]);
    const initWebhook = useRef([]);

    useEffect(() => {
        setNotifStatus(enableTeamsNotif);
        setChannel(teamsChannel);
        setWebhook(teamsWebhook);
    }, [enableTeamsNotif, teamsChannel, teamsWebhook]);

    const handleNotifStatusChange = () => setNotifStatus(!notifStatus);

    const handleChannelChange = (event) => setChannel(event.target.value);

    const handleWebhookChange = (event) => setWebhook(event.target.value);

    useEffect(() => {
        const newObject = { ...configParamsToSave };
        newObject.teams_updates = notifStatus;
        newObject.teams_channel = channel;
        newObject.teams_webhook = webhook;
        setConfigParamsToSave(newObject);

        if (!Object.keys(initNotifStatus).find(elem => elem === "current") || initNotifStatus.current.length === 0) 
            initNotifStatus.current = notifStatus;
        if (!initChannel.current.length && initChannel.current !== "") initChannel.current = channel;
        if (!initWebhook.current.length && initWebhook.current !== "") initWebhook.current = webhook;

        setModalSaveStatus(
            initNotifStatus.current !== notifStatus ||
            initChannel.current !== channel ||
            initWebhook.current !== webhook
        );
    }, [notifStatus, channel, webhook]);

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);

    return (
        <div className="modal-elems-rows">
            <div>
                <h5 className='small-heading'>CONFIGURATION</h5>
                <h2>Teams notifications</h2>
            </div>
            <div className="container-guide modal-panel-button-row">
                <div>
                    <Link className="panel-button-container  grey-bg" target="_blank" to={notifImplementationLink}>
                        <div className="file-icon">
                            <FiletypeDoc style={{ color: colors.ccBlue }} size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Implementation guide</div>
                            <div className="panel-button-desc">Guide how to set up Teams notifications</div>
                        </div>
                    </Link>
                </div>
            </div>

            <Flex align="center" gap={6}>
                <Switch size="small" checked={notifStatus} onChange={handleNotifStatusChange} />
                <>Enable Teams notifications</>
            </Flex>
            <div style={{ position: 'relative' }}>
                {
                    showGuideDiv &&
                    <div className='hoverable-div-parent' style={{ left: '10em' }}>
                        <p className='hover-content'>
                            Check out our easy-to-follow guides at the top of
                            the page to ensure successful configuration and setup of Teams notifications
                        </p>
                    </div>
                }
                <h3 className="modal-category mb-0">
                    Account details
                    <InfoCircleFill
                        size={15}
                        className='info-icon'
                        onMouseEnter={() => setShowGuideDiv(true)}
                        onMouseLeave={() => setShowGuideDiv(false)}
                    />
                </h3>
                <p>
                    Add the incoming webhook of your Teams channel below.
                </p>
                <Input className='wide-input' type="text" placeholder="https://xxxxx.webhook.office.com/xxxxxxxxx" defaultValue={webhook} onChange={handleWebhookChange} />
            </div>

            <div>
                <p className='mb-2'>
                    Add the name of the Teams channel where you would
                    like to receive your notifications.
                </p>
                <Input className='wide-input' type="text" placeholder="code-cube-alerts" defaultValue={channel} onChange={handleChannelChange} />
            </div>
        </div>
    );
}
