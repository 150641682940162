import { React, useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FiletypeDoc } from 'react-bootstrap-icons';
import { Input, Switch, Flex } from 'antd';

import colors from '../../../../../styles/_colors.scss';

const _ = require('lodash');

export const EmailsNotifModal = ({ context }) => {
    const {
        notifImplementationLink,
        enableEmailNotif,
        curEmailsList,
        setConfigParams,
        setModalSaveStatus = () => {},
    } = useContext(context);

    const [notifStatus, setNotifStatus] = useState(enableEmailNotif);
    const [emailsList, setEmailsList] = useState(curEmailsList);
    const [inputValue, setInputValue] = useState("");
    const [configParamsToSave, setConfigParamsToSave] = useState({});

    const initNotifStatus = useRef([]);
    const initEmailsList = useRef([]);

    useEffect(() => {
        setNotifStatus(enableEmailNotif);
        setEmailsList(curEmailsList);
        
        if (!Object.keys(initNotifStatus).find(elem => elem === "current") || initNotifStatus.current.length === 0) 
            initNotifStatus.current = enableEmailNotif;
        if (!initEmailsList.current.length && initEmailsList.current !== "") initEmailsList.current = curEmailsList;
    }, [enableEmailNotif, curEmailsList]);
    
    const handleNotifStatusChange = () => {
        setNotifStatus(!notifStatus);
    }

    const handleInputChange = (event) => {
        const enterKeys = [";", ",", "Enter"];

        if (enterKeys.indexOf(event?.key) > -1 && inputValue !== "") {
            const newList = [ ...emailsList, inputValue.trim().replace(";", "") ];
            setEmailsList(newList);
            setInputValue("");
        }
        else if (enterKeys.indexOf(event.target.value) < 0) setInputValue(event.target.value);
    }

    const handleRemoveTag = (elem) => {
        const newList = emailsList.filter(email => email !== elem);
        setEmailsList(newList);
    }

    useEffect(() => {
        const newObject = { ...configParamsToSave };
        newObject.email_updates = notifStatus;
        newObject.emails = emailsList.join(";");
        setConfigParamsToSave(newObject);

        if (initNotifStatus.current.length === 0) initNotifStatus.current = notifStatus;
        if (initEmailsList.current.length === 0) initEmailsList.current = emailsList;

        setModalSaveStatus(
            initNotifStatus.current !== notifStatus ||
            !_.isEqual(initEmailsList.current, emailsList)
        );
    }, [notifStatus, emailsList]);

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);

    return(
        <div className="modal-elems-rows">
            <div>
                <h5 className='small-heading'>CONFIGURATION</h5>
                <h2>Email notifications</h2>
            </div>
            <div className="container-guide modal-panel-button-row">
                <div>
                    <Link className="panel-button-container grey-bg" target="_blank" to={notifImplementationLink}>
                        <div className="file-icon">
                            <FiletypeDoc style={{ color: colors.ccBlue }} size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Implementation guide</div>
                            <div className="panel-button-desc">Guide how to set up email notifications</div>
                        </div>
                    </Link>
                </div>
            </div>

            <Flex align="center" gap={6}>
                <Switch size="small" checked={notifStatus} onChange={handleNotifStatusChange} />
                <>Enable notifications</>
            </Flex>
            <div>
                <h3 className="modal-category">Email list</h3>
                <p className='pb-3'>
                    Add the email addresses from the contacts
                    that should receive the mail notifications below. 
                    <br></br>
                    Separate the emails by entering a comma or 
                    by pressing the enter button.
                </p>
                <div id="email_notif_container" className="tags-container">
                    <Input className='wide-input' type="email" placeholder="Add emails" value={inputValue} onChange={handleInputChange} onKeyDown={handleInputChange} />
                    <div id="email_notif_list" className="tags-list">
                        { emailsList.map((elem, idx) => {
                            if (elem !== "")
                                return(
                                    <span className="tag" key={idx}>
                                        {elem}
                                        <span className="remove" onClick={() => handleRemoveTag(elem)}>x</span>
                                    </span>)
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
